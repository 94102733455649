import { Route, Switch } from 'react-router-dom';
import { Home, Rules } from '../app/index';
import PageNotFound from '../app/PageNotFound/PageNotFound';
import { RoutePattern } from './RoutePattern';

export default function AppRoutes() {
  return (
    <Switch>
      <Route exact path={RoutePattern.Home} component={Home} />
      <Route exact path={RoutePattern.Rules} component={Rules} />
      <Route exact path="*" component={PageNotFound} />
    </Switch>
  );
}
