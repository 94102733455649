import MELLY_GLOBE from '../../assets/images/MELLY_GLOBE.png';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RoutePattern } from '../../routes/RoutePattern';
import { setCookie } from '../../helpers/cookies';

const Image = styled.img`
  height: 350px;

  @media (min-width: 1024px) {
    height: 450px;
  }
  @media (min-width: 1536px) {
    height: 600px;
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-transform: uppercase;
`;

export default function Home() {
  useEffect(() => {
    import('../../components/FontLoader/FontLoader');
    setCookie('splash', 'cookie-test', '.ynw4life.com', 0.003472221); // set cookie to 5 mins
  }, []);

  return (
    <div className="text-center relative flex flex-col justify-center items-center h-full min-h-screen font-druk-wide-bold text-white py-10">
      <Image src={MELLY_GLOBE} alt="melly globe" className="w-auto mx-auto" />
      <div className="mt-5 lg:mt-10">
        <p className="text-white text-xxxs sm:text-xxs md:text-xs xl:text-xl font-druk-wide-bold">
          Melly is looking for 15 of his most creative fans to design art inspired <br />
          by his music for a chance to be a part of Melly's next album cover!
        </p>

        <p className="text-primary text-xxs md:text-xs xl:text-xl mt-2 md:mt-5 font-druk-wide-medium">
          To submit a valid entry, follow the instructions below:
        </p>

        <p className="text-xxxs sm:text-xxs md:text-xs xl:text-xl mt-2 md:mt-5 font-druk-wide-medium">
          <span className="text-primary font-druk-wide-medium">Via Instagram: </span>Post Art
          submission and tag @YNWMelly #FreeMellyArt and #ContestEntry <br />
        </p>

        <p className="text-xxxs sm:text-xxs md:text-xs xl:text-xl mt-2 md:mt-5 font-druk-wide-medium">
          <span className="text-primary font-druk-wide-medium">Via Email: </span>Send Art submission
          to FreeMellyArt@gmail.com with full contact information (First and last name).
        </p>

        <p className="text-xxxs sm:text-xxs md:text-xs xl:text-xl text-primary mt-2 md:mt-5 font-druk-wide-medium">
          Deadline for all submissions is April 15, 2021. Good Luck!
        </p>

        <p className="text-xxxs sm:text-xxs md:text-xxs xl:text-xs mt-2 md:mt-5">
          View Official rules for more details here:{' '}
          <Link to={RoutePattern.Rules} rel="noreferrer" target="_blank" className="text-primary" data-track="rules">
            Contest Rules
          </Link>
        </p>
        <p className="text-xxxs sm:text-xxs md:text-xxs xl:text-xs mt-2 md:mt-2 xl:mt-4 px-10">
          NO PURCHASE NECCESSARY OPEN TO LEGAL RESIDENTS OF US (+DC) 16+ . VOID WHERE PROHIBITED
        </p>

        <div className="text-xxxs sm:text-xxs md:text-xs xl:text-lg mt-5 md:mt-10">
          <a
            href="https://ynw4life.com/"
            // href="http://localhost/ynw-melly/"
            rel="noreferrer"
            className="bg-primary text-white py-2 px-3 md:py-3 md:px-5"
            data-track="enter-website"
          >
            ENTER WEBSITE
          </a>
        </div>
        <div className="text-xxxs sm:text-xxs md:text-xxs xl:text-xxs mt-5 md:mt-10">
          <a
            href="https://privacy.wmg.com/atlantic/privacy-policy"
            rel="noreferrer"
            className="text-white py-2 px-3 md:py-3 md:px-5"
            target="_blank"
          >
            PRIVACY POLICY
          </a>
          <a
            href="https://www.atlanticrecords.com/terms-of-use"
            rel="noreferrer"
            className="text-white py-2 px-1 md:py-3 md:px-1"
            target="_blank"
          >
            TERMS OF USE
          </a>
          <a 
            href="https://www.wminewmedia.com/cookies-policy/"
            rel="noreferrer"
            className="text-white py-2 px-3 md:py-3 md:px-5"
            target="_blank"
          >
            COOKIES POLICY
          </a>
          <StyledLink className="ot-sdk-show-settings">Cookies Settings</StyledLink>
        </div>
      </div>
    </div>
  );
}
