export default function Rules() {
  return (
    <div className="h-screen w-full bg-white">
      <iframe
        title="contest rules"
        src="https://ynw4life.com/contest-official-rules.html"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}
