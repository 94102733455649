import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import './styles/main.css';

function App() {
  return (
    <div className="app min-h-screen">
      <Router>
        <Route path="*" component={AppRoutes} />
      </Router>
    </div>
  );
}

export default App;
